import _export from "../internals/export";
import _fails from "../internals/fails";
import _objectGetOwnPropertyNamesExternal from "../internals/object-get-own-property-names-external";
var $ = _export;
var fails = _fails;
var getOwnPropertyNames = _objectGetOwnPropertyNamesExternal.f; // eslint-disable-next-line es-x/no-object-getownpropertynames -- required for testing

var FAILS_ON_PRIMITIVES = fails(function () {
  return !Object.getOwnPropertyNames(1);
}); // `Object.getOwnPropertyNames` method
// https://tc39.es/ecma262/#sec-object.getownpropertynames

$({
  target: "Object",
  stat: true,
  forced: FAILS_ON_PRIMITIVES
}, {
  getOwnPropertyNames: getOwnPropertyNames
});
export default {};